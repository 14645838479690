import {
  HttpErrorResponse,
  type HttpEvent,
  type HttpHandlerFn,
  type HttpRequest
} from '@angular/common/http';
import { inject } from '@angular/core';

import { catchError, throwError, type Observable } from 'rxjs';

import { AppService } from '../app.service';

export function authInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  const appService = inject(AppService);

  return next(req).pipe(
    catchError((err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 403) {
          appService?.showToaster(
            "warning", 
            "Atenção!",
            `${err.status} - Token expirado. Faça login novamente.`
          );
          
          setTimeout(() => {
            localStorage.clear();
            location.reload();
          }, 5000);
        }
      }

      return throwError(() => err); 
    })
  );
}