import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { User } from '../shared/utils/utils.model';

@Injectable()
export class TokenStorage {
  http: any;

  /**
   * Get access token
   * @returns {Observable<string>}
   */
  public getAccessToken(): Observable<string> {
    const token: string = <string>localStorage.getItem('token');
    return of(token);
  }

  public getAccessTokenString(): string {
    let token: string = <string>localStorage.getItem('token');
    return token;
  }

  /**
   * Get refresh token
   * @returns {Observable<string>}
   */
  public getUserToken(): Observable<User> {
    const token: any = JSON.parse(<string>localStorage.getItem('user'));
    return of(token);
  }

  /**
   * Set access token
   * @returns {TokenStorage}
   */
  public setAccessToken(token: string): TokenStorage {
    localStorage.setItem('token', token);
    return this;
  }

  /**
  * Set refresh token
  * @returns {TokenStorage}
  */
  public setUserToken(user: User): TokenStorage {
    localStorage.setItem('user', JSON.stringify(user));
    return this;
  }

  /**
  * Remove tokens
  */
  public clear() {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('signatureLocal');
  }
}
