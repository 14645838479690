import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { BlockUiComponent } from './shared/block-ui/block-ui.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet, BlockUiComponent]
})
export class AppComponent {
  title = 'painel-newe';
}
