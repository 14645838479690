import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { AuthService } from 'ngx-auth';
import { TokenStorage } from './token-storage.service';
import { BaseUrls } from '../app.config';

import { tap, map, catchError } from 'rxjs/operators';
import { Util } from '../shared/utils/utils';
import { User } from '../shared/utils/utils.model';

@Injectable()
export class AuthenticationService implements AuthService {

  constructor(
    private http: HttpClient,
    private tokenStorage: TokenStorage
  ) {}

  /**
   * Check, if user already authorized.
   * @description Should return Observable with true or false values
   * @returns {Observable<boolean>}
   * @memberOf AuthService
   */
  public isAuthorized(): Observable < boolean > {

    let accessToken = this.tokenStorage
      .getAccessToken();
    // console.log(accessToken);
    return accessToken.pipe(map(token => !!token));
  }

  /**
   * Get access token
   * @description Should return access token in Observable from e.g.
   * localStorage
   * @returns {Observable<string>}
   */
  public getAccessToken(): Observable < string > {
    return this.tokenStorage.getAccessToken();
  }

  /**
   * Function, that should perform refresh token verifyTokenRequest
   * @description Should be successfully completed so interceptor
   * can execute pending requests or retry original one
   * @returns {Observable<any>}
   */
  public refreshToken(): Observable <User> {

    return this.tokenStorage
      .getUserToken()
      /*.switchMap((refreshToken: UserData) => {
        return this.http.post < UserData >(this.backUrl+`/v1/companies/auth`, { refreshToken });
      })*/
      .pipe(
          tap(this.saveAccessData.bind(this)),
          catchError((err) => {
            this.logout();
            return throwError(() => err);
          })
      )
  }

  /**
   * Function, checks response of failed request to determine,
   * whether token be refreshed or not.
   * @description Essentialy checks status
   * @param {Response} response
   * @returns {boolean}
   */
  public refreshShouldHappen(response: HttpErrorResponse): boolean {
    return response.status === 401
  }

  /**
   * Verify that outgoing request is refresh-token,
   * so interceptor won't intercept this request
   * @param {string} url
   * @returns {boolean}
   */
  public verifyTokenRequest(url: string): boolean {
    return url.endsWith('/refresh');
  }

  /**
   * EXTRA AUTH METHODS
   */
  public login(email: string, password: string): Observable<any> {

    const passwordB64 = btoa( password);
    const headers = new HttpHeaders()
                .set('Content-Type', 'application/json; charset=utf-8');

    return this.http.post(`${BaseUrls.newe}/auth`, {email: email, password: passwordB64}, {headers: headers})
    .pipe(
      tap((tokens: User) => this.saveAccessData(tokens))
    )
  }

  /**
   * Logout
   */
  public logout(): void {
    this.tokenStorage.clear();
    location.reload();
  }

  /**
   * Save access data in the storage
   *
   * @private
   * @param {AccessData} data
   */
  private saveAccessData(user: any) {
    if(user && user.tokenResponse){
      this.tokenStorage
        .setAccessToken(user.tokenResponse.token)
        .setUserToken(user.user);
    }
  }

}
