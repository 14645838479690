import { Injectable, Output, EventEmitter } from "@angular/core";
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { BaseUrls } from 'src/app/app.config';
import { TokenStorage } from 'src/app/auth/token-storage.service';

@Injectable()
export class LayoutService {

  @Output() notifier = new EventEmitter<string>();
  @Output() notifierStorage = new EventEmitter<string>();

  constructor(
    private http: HttpClient,
    private tokenStorage: TokenStorage
  ) {}

  public setTitle(title: string) {
    this.notifier.emit(title);
  }

  public updateUserStorage(flag: string) {
    this.notifierStorage.emit(flag);
  }

  public verifyTokenIsValid(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8')
                                    .set('Authorization', this.tokenStorage.getAccessTokenString());
    return this.http.get<any>(`${BaseUrls.newe}/v1/user/test/token`, { headers: headers });
  }

  public verifyPermissionUser(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8')
                                    .set('Authorization', this.tokenStorage.getAccessTokenString());

    return this.http.get<any>(`${BaseUrls.newe}/v1/level-user`, { headers: headers });
  }
}
