import { Routes } from '@angular/router';

import { publicGuard, protectedGuard } from 'ngx-auth';
import { LayoutComponent } from './layout/layout.component';

export const APP_ROUTES: Routes = [
  {
    path: 'login',
    canActivate: [ publicGuard ],
    loadComponent: () => import('./login/login.component').then(c => c.LoginComponent)
  },
  {
    path: 'restricted',
    canActivate: [ protectedGuard ],
    loadChildren: () => import('./restricted/restricted.routes').then(m => m.RESTRICTED_ROUTES),
    component: LayoutComponent
  },
  {
    path: 'retrieve-password',
    canActivate: [ publicGuard ],
    loadComponent: () => import('./retrieve-password/retrieve-password.component').then(c => c.RetrievePasswordComponent)
  },
  {
    path: 'formulario-corretor',
    canActivate: [ publicGuard ],
    loadComponent: () => import('./broker-form/broker-form.component').then(c => c.BrokerFormComponent)
  },
  {
    path: 'acompanhamento',
    canActivate: [ publicGuard ],
    loadComponent: () => import('./broker-follow-up/broker-follow-up.component').then(c => c.BrokerFollowUpComponent)
  },
  {
    path: 'aviso',
    canActivate: [ publicGuard ],
    loadComponent: () => import('./completed-form/completed-form.component').then(c => c.CompletedFormComponent)
  },
  {
    path: '',
    redirectTo: 'restricted/home',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'restricted/home',
    pathMatch: 'full'
  }
];