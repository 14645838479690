import { enableProdMode, LOCALE_ID, importProvidersFrom } from '@angular/core';
import { CommonModule, registerLocaleData  } from '@angular/common';
import { withInterceptorsFromDi, provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { PreloadAllModules, RouterModule, provideRouter, withPreloading } from '@angular/router';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import localePt from '@angular/common/locales/pt';

import { provideToastr } from 'ngx-toastr';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { AuthenticationModule } from './app/auth/authentication.module';
import { AppService } from './app/app.service';
import { LayoutService } from './app/layout/layout.service';
import { BlockUiService } from './app/shared/block-ui/block-ui.service';
import { APP_ROUTES } from './app/app.routes';
import { AngularGoogleMapsService } from './app/shared/angular-google-maps/angular-google-maps.service';
import { TimelineService } from './app/shared/timeline/timeline.service';
import { authInterceptor } from './app/auth/auth.interceptor';
import { UploadService } from './app/upload.service';

registerLocaleData(localePt);

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(
          CommonModule,
          BrowserModule,
          RouterModule,
          AuthenticationModule,
          BsDatepickerModule.forRoot(),
        ),
        {
          provide: LOCALE_ID,
          useValue: 'pt'
        },
        BlockUiService,
        LayoutService,
        AppService,
        UploadService,
        TimelineService,
        AngularGoogleMapsService,
        provideAnimations(),
        provideToastr({
          progressBar: true,
          progressAnimation: "decreasing"
        }),
        provideRouter(APP_ROUTES, withPreloading(PreloadAllModules)),
        provideHttpClient(
          withInterceptorsFromDi(),
          withInterceptors([authInterceptor])
        ),
    ]
})
  .catch(err => console.error(err));
