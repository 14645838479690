import { EventEmitter, Output, Injectable } from "@angular/core";

@Injectable()
export class BlockUiService {
    @Output() notifier = new EventEmitter<string>();
    @Output() start = new EventEmitter<string>();
    @Output() stop = new EventEmitter<string>();

    public notify(message: string) {
        this.notifier.emit(message);
    }

    public startBlock(flag: string) {
        this.start.emit(flag);
    }

    public stopBlock(flag: string) {
        this.stop.emit(flag);
    }
}
