<div class="app-body">

  <header class="header-container">
    <div class="header-content">
      <div class="button-menu d-block d-md-none" (click)="openSideNav()">
        <i class="fa fa-bars" aria-hidden="true"></i>
      </div>

      <div class="title">
        <h2>{{ title }}</h2>
      </div>

      <div class="options-container">
        <div class="user">
          <img [src]="user?.photo" alt="Imagem do usuário">
          <span class="capitalize">{{ user?.firstName}}</span>
        </div>

        <div class="logout mr-2" (click)="logout()">
          <span class="d-none d-md-block">
            Sair
            <i aria-hidden="true" class="fa fa-power-off mx-2"></i>
          </span>
          <span class="d-block d-md-none" title="Sair">
            <i aria-hidden="true" class="fa fa-power-off mx-2"></i>
          </span>
        </div>
      </div>

    </div>
  </header>

  <div class="menu-container" (mouseleave)="closeSideNav()">
    <div id="mySidenav" class="sidenav" [class.open]="flagSideNav" (mouseover)="openSideNav()">

      <div class="logo">
        <img src="assets/LOGO-NEWE-AGRO-3.png" alt="Logo IZConsultores">
      </div>

      <ul>
        <div>
          @if(user?.userClass?.id !== 5) {
            <li
              (click)="closeSideNav()"
              [routerLink]="['/restricted/home']"
              [routerLinkActive]="'active'"
            >
              <i class="fa fa-globe ml-1" aria-hidden="true"></i>
              <a>Cotação com croqui</a>
            </li>
          }
          
          <li
            (click)="closeSideNav()"
            [routerLink]="['/restricted/usuario']"
            [routerLinkActive]="'active'"
          >
            <i class="fa fa-user ml-1" aria-hidden="true"></i>
            <a>Meus Dados</a>
          </li>

          @if(user?.userClass?.id === 4) {
            <li
              (click)="closeSideNav()"
              [routerLink]="['/restricted/acesso']"
              [routerLinkActive]="'active'"
            >
              <i class="fa fa-lock ml-1" aria-hidden="true"></i>
              <a>Controle de acesso</a>
            </li>
          }

          @if(user?.userClass?.id === 4 || user?.userClass?.id === 5) {
            <li
              (click)="closeSideNav()"
              [routerLink]="['/restricted/corretor']"
              [routerLinkActive]="'active'"
            >
              <i class="fa fa-users" aria-hidden="true"></i>
              <a>Corretores</a>
            </li>
          }

        </div>

        <div>
          <li
            (click)="closeSideNav()"
            [routerLink]="['/restricted/suporte']"
            [routerLinkActive]="'active'"
          >
          <i class="fa fa-envelope" aria-hidden="true"></i>
            <a>Suporte</a>
          </li>

          @if(user?.userClass?.id === 4 || user?.userClass?.id === 5) {
            <li
              (click)="closeSideNav()"
              [routerLink]="['/restricted/configuracoes/status']"
              [routerLinkActive]="'active'"
            >
            <i class="fa fa-cog" aria-hidden="true"></i>
              <a>Configurações</a>
            </li>
          }
        </div>
      </ul>

    </div>
  </div>

  <div class="app-body">
    <div class="main" (click)="closeSideNav()">
      <router-outlet></router-outlet>
    </div>
  </div>

</div>

<div class="app-footer">
  <div class="footer-content">
    <div class="row pt-2">
      <div class="col-md-12 text-center">
        <span>Desenvolvido por: </span>
        <a href="http://www.izagro.com.br" target="_blank">
          <img src="https://s3-sa-east-1.amazonaws.com/images.izagro.com.br/izagro-site-v2/izlogoasset.png" alt="Logo IZagro">
          IZagro &copy; 2024
        </a>
      </div>
    </div>
  </div>
</div>
