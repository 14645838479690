import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Observable } from "rxjs";

import { ToastrService } from "ngx-toastr";
import { BaseUrls } from "./app.config";
import { City, Culture, State } from "./app.model";


@Injectable()
export class AppService {

  private http = inject(HttpClient);
  // private toasterService = ToastrService

  constructor(
    private toastr: ToastrService
  ) {}

  public getStates(): Observable<State[]> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.http.get<State[]>(`${BaseUrls.newe}/v1/public/state`, { headers: headers })
  }

  public getCitiesByState(state: State): Observable<City[]> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.http.get<City[]>(`${BaseUrls.newe}/v1/public/city/${state.id}`, { headers: headers })
  }

  public getCulturesNewe(): Observable<Culture[]> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.http.get<Culture[]>(`${BaseUrls.newe}/v1/culture`, { headers: headers })
  }

  public showToaster(type: any, title: string, message: string) {
    // const toast: Toast = {
    //   type: type,
    //   title: title,
    //   body: message,
    //   progressBar: true,
    //   progressBarDirection: 'decreasing'  
    // };
    // this.toasterService.pop(toast);
    
    switch (type) {
      case "success":
        this.toastr.success(message, title);
        break;
      case "error":
        this.toastr.error(message, title);
        break;
      case "warning":
        this.toastr.warning(message, title);
        break;
      case "info":
        this.toastr.info(message, title);
        break;
    
      default:
        this.toastr.show(message, title);
        break;
    }
  }
}
