import { EventEmitter, Output, Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class AngularGoogleMapsService {
  @Output() notifier = new EventEmitter<void>();

  public notify() {
    this.notifier.emit();
  }
}
