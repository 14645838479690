import { Observable, throwError } from "rxjs";
import { User, CurrentDate } from './utils.model';

export class Util {

  static debounce(func, wait, immediate) {
    var timeout;
    return function () {
      var context = this, args = arguments;
      var later = () => {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }

  static isOnScreen(element: any): boolean {
    let win = window;
    let screenTop = win.scrollY - 200;
    let screenBottom = screenTop + win.innerHeight;
    let elementTop = element.offsetTop;
    let elementBottom = elementTop + element.offsetHeight;

    return elementBottom > screenTop && elementTop < screenBottom;
  }

  static getLogin(): User {
    if (localStorage.getItem('user')) {
      let login: User;
      try {
        login = JSON.parse(localStorage.getItem('user'));
      } catch (error) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        return null;
      }
      return login;
    }
    return null;
  }

  static getDate() {
    let d = new Date();
    let currentDate: CurrentDate = <CurrentDate>{};
    let days = ['Domingo', 'Segunda','Terça','Quarta','Quinta','Sexta','Sabado'];
    let month = ['Janeiro','Fevereiro','Março','Abril','Maio','Junho','Julho','Agosto','Setembro','Outubro','Novembro','Dezembro'];

    currentDate.date = d.getDate();
    currentDate.day = days[d.getDay()];
    currentDate.monthNumber = d.getMonth() + 1;
    currentDate.monthString = month[d.getMonth()];
    currentDate.fullYear = d.getFullYear();
    currentDate.hours = d.getHours();
    currentDate.minutes = d.getMinutes();
    currentDate.seconds = d.getSeconds();

    return currentDate;
  }

  static maskCpf(value) {
    value = value.replace(/(\d{3})(\d)/,"$1.$2")
    value = value.replace(/(\d{3})(\d)/,"$1.$2")
    value = value.replace(/(\d{3})(\d{1,2})$/,"$1-$2")

    return value
  }

  static formatNumber(value) {
    if (value.length === 5 && value == '10000') {
      return value.replace(/(\d{3})(\d)/,"$1.$2");
    }

    return value.replace(/(\d{2})(\d)/,"$1.$2");
  }

  static maskCnpj(value) {
    value = value.replace(/^(\d{2})(\d)/,"$1.$2")
    value = value.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3")
    value = value.replace(/\.(\d{3})(\d)/,".$1/$2")
    value = value.replace(/(\d{4})(\d)/,"$1-$2")

    return value;
  }

  static maskBirthdate(value) {
    value = value.replace(/^(\d{2})(\d)/,"$1-$2")
    value = value.replace(/^(\d{2})\-(\d{2})(\d)/,"$1-$2-$3")

    return value;
  }

  static maskPostalCode(value) {
    value = value.replace(/^(\d{5})(\d)/,"$1-$2")

    return value;
  }

  static generateRandomColor(){
    let hexadecimals = '0123456789ABCDEF';
    let color = '#';

    for (let i = 0; i < 6; i++ ) {
      color += hexadecimals[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  static createSlug(phrase: string) {
    let sentenceWithoutAccent = this.removeAccents(phrase);
    let sentenceWithoutSpecialCharacter = sentenceWithoutAccent.replace(/[^\w\s-]/g, '');
    const slug = sentenceWithoutSpecialCharacter.toLowerCase().split(' ').join('-');
    
    return slug;
  }

  static removeAccents(phrase: string) {
    var accentsHexadecimalsMap = {
      a: /[\xE0-\xE6]/g,
      e: /[\xE8-\xEB]/g,
      i: /[\xEC-\xEF]/g,
      o: /[\xF2-\xF6]/g,
      u: /[\xF9-\xFC]/g,
      c: /\xE7/g,
      n: /\xF1/g
    };
  
    for (let letter in accentsHexadecimalsMap) {
      const regex = accentsHexadecimalsMap[letter];
      phrase = phrase.replace(regex, letter);
    }
  
    return phrase;
  }

  static formatBytes(bytes: number) {
    const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];

    let value = bytes;
    let unitIndex = 0

    while (value >= 1024 && unitIndex < units.length -1) {
      value /= 1024
      unitIndex++
    }

    return `${value.toFixed(1)}${units[unitIndex]}`
  }
}
