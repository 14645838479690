import { Component, OnDestroy, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { BlockUiService } from './block-ui.service';
import { Subscription, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
    selector: 'app-block-ui',
    templateUrl: './block-ui.component.html',
    styleUrls: ['./block-ui.component.css'],
    animations: [
        trigger('block-ui', [
            state('hide', style({
                opacity: 0,
                visibility: 'hidden',
            })),
            state('show', style({
                opacity: 0.85,
                visibility: 'visible',
            })),
            transition('hide => show', animate('0ms 0s ease-in')),
            transition('show => hide', animate('350ms 0s ease-out'))
        ])
    ],
    standalone: true
})
export class BlockUiComponent implements OnInit, OnDestroy {

  public message: string;
  public blockUi: string = "hide";

  public unsubscribe: Subscription;

  constructor(
    private blockUiService: BlockUiService
  ) { }

  ngOnInit() {
    this.unsubscribe = this.blockUiService.notifier.subscribe(message => {
      this.message = message;
    })

    this.unsubscribe = this.blockUiService.start.subscribe(flag => {
      this.blockUi = flag;
    })

    this.unsubscribe = this.blockUiService.stop.pipe(
      switchMap(message => timer(1000))
    ).subscribe(timer => {
      this.blockUi = "hide";
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe.unsubscribe();
  }

}
