<div class="block-ui" [@block-ui]="blockUi">
    <div class="loading-info">
        <div class="loading-text loading">
            <div class="text">
              <i class="fa fa-spinner fa-pulse fa-3x fa-fw mr-1"></i>
              <span>{{ message }}</span>
            </div>
        </div>
    </div>
</div>
