import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable, Output, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseUrls } from 'src/app/app.config';

@Injectable({
  providedIn: 'root'
})
export class TimelineService {

  @Output() notifier = new EventEmitter<void>();

  private http = inject(HttpClient);

  public onOpenModal() {
    this.notifier.emit();
  }

  public getBrokerAnalysisByBrokerId(brokerId: number): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    return this.http.get<any>(`${BaseUrls.newe}/v1/analysis/broker/${brokerId}`, { headers: headers })
  }
}
