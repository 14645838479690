import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppService } from '../app.service';
import { AuthenticationService } from '../auth/authentication.service';
import { BlockUiService } from '../shared/block-ui/block-ui.service';
import { Util } from '../shared/utils/utils';
import { User } from './layout.model';
import { LayoutService } from './layout.service';
import { NgIf } from '@angular/common';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  standalone: true,
  imports: [
    RouterLink,
    RouterLinkActive,
    NgIf,
    RouterOutlet
  ]
})
export class LayoutComponent implements OnInit {

  public unsubscribe: Subscription;

  public flagSideNav: boolean = false;
  public title: string = "";

  public user: User = <User>{};

  constructor(
    private layoutService: LayoutService,
    private authService: AuthenticationService,
    private cdRef: ChangeDetectorRef,
    private blockUiService: BlockUiService,
    private appService: AppService
  ) {}

  ngOnInit() {
    this.user = Util.getLogin();
    this.unsubscribe = this.layoutService.notifier.subscribe((title: string) => {
      this.title = title;
    });

    this.unsubscribe = this.layoutService.notifierStorage.subscribe((flag: string) => {
      if (flag == "update") {
        this.user = Util.getLogin();
      }
    });
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  public logout() {
    this.authService.logout();
  }

  public openSideNav() {
    this.flagSideNav = true;
  }

  public closeSideNav() {
    this.flagSideNav = false;
  }

  public verifyTokenIsValid() {
    this.layoutService.verifyTokenIsValid()
      .subscribe({
        next: data => {
          return;
        },
        error: error => {
          console.log(error);
          if (error.status == 403) {
            this.appService.showToaster(
                "warning", 
                "Atenção!",
                `${error.status} - Token expirado. Faça login novamente.`
              );
            setTimeout(() => {
              localStorage.clear();
              location.reload();
            }, 5000);
          }
        }
      })
  }

}
