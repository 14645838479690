import { environment } from "src/environments/environment";

export class BaseUrls{

    //Base URLs APIs

    // public static newe: string = 'http://25.53.169.43:8080';
    public static newe: string = 'https://estimate.newecorretor.com.br'; //Production
    // public static newe: string = 'https://estimate-hml.newecorretor.com.br'; //Homologation


    public static production = environment.production;
    public static version = "1.4.0";

}
